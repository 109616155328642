import * as React from "react"
import { Link } from "gatsby"

import LayoutOpenHouses from "../components/layout/landing/layout-open-houses"
import Seo from "../components/seo"
import OpenHousesHeroSection from "../components/sections/open-houses-hero-section"
import OpenHousesVideoSection from "../components/sections/open-houses-video-section"
import OpenHousesHowItWorksSection from "../components/sections/open-houses-how-it-works-section"
import OpenHousesBenefitsSection from "../components/sections/open-houses-benefits-section"
import OpenHousesHeroMobileSection from "../components/sections/open-houses-hero-mobile-section"

const OpenHousesPage = () => (
  <LayoutOpenHouses>
    <Seo title="Open Houses" description="Companies all across the US who have multiple openings in one location, use HireLive to help them promote their very own Hiring Event to fill any type of position." />

    <OpenHousesHeroSection />

    <OpenHousesHeroMobileSection />

    <OpenHousesVideoSection />

    <div className="bg-site-indigo-100">
      <div className="w-11/12 max-w-screen-lg flex flex-col items-center justify-center mx-auto pt-16 pb-16">
        <p className="text-xl md:text-2xl text-gray-800">You give us the date, location and position, HireLive can bring the Career Fair to you. With as little as two weeks' notice, in any market across all 50 states, HireLive can help you meet with a vast number of candidates face to face. All you have to do is show up and interview. HireLive will launch a marketing campaign. We use resources to advertise and attract candidates who apply directly to the position by using online job boards, email campaign to databases, text campaigns, social networking and personalized phone calls to ensure the applicants are prepared to meet with you on the day, time and location of your choice. This service is ideal when you are looking to fill multiple openings at once. Each <strong>“Open House”</strong> is produced for one low flat fee. Whether you hire 1 or multiple candidates, there is no additional cost per hire.</p>

        <Link to="/schedule-open-house">
        <button
          className="bg-site-green-900 hover:bg-site-green-800 text-white uppercase font-display font-bold py-3 px-6 mt-8"
        >
          Schedule Your Open House
        </button>
      </Link>
      </div>
    </div>

    <OpenHousesHowItWorksSection />

    <OpenHousesBenefitsSection />
  </LayoutOpenHouses>
)

export default OpenHousesPage