import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const OpenHousesHeroMobileSection = () => (
  <div className="md:hidden">
    <StaticImage
      width={767}
      height={500}
      layout="constrained"
      // This is a presentational image, so the alt should be an empty string
      alt=""
      // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
      src="../../images/backgrounds/open-houses-hiring.jpg"
    />

    <div className='w-full max-w-screen-xl bg-site-indigo-100 flex flex-col xl:flex-row items-center mx-auto pt-6 pb-10'>
      <div className='w-11/12 flex flex-col items-center mx-auto'>
        <h1 className='text-4xl font-extrabold text-site-indigo-900 text-center'>
          <span className='leading-tight'>Stress-Free Recruiting and Interviewing</span>
        </h1>

        <h2 className='text-2xl md:text-3xl font-medium text-gray-800 text-center mt-3'>
          Meet Your Qualified Candidates Face to Face with Open Houses
        </h2>

        <Link to="/schedule-open-house">
          <button
            className="bg-site-green-900 hover:bg-site-green-800 text-white uppercase font-display font-bold py-3 px-6 mt-8"
          >
            Schedule Your Open House
          </button>
        </Link>
      </div>
    </div>
  </div>
)

export default OpenHousesHeroMobileSection