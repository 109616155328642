import React from 'react'
import { Link } from "gatsby"

import NumberOne from '../../assets/number-1.svg'
import NumberTwo from '../../assets/number-2.svg'
import NumberThree from '../../assets/number-3.svg'
import NumberFour from '../../assets/number-4.svg'
import NumberFive from '../../assets/number-5.svg'

const OpenHousesHowItWorksSection = () => (
  <div className="bg-white w-full py-16 border-b">
    <div className="w-11/12 max-w-3xl mx-auto">
      <h2 className='text-4xl md:text-5xl text-site-indigo-900 text-center font-bold mb-12'>How HireLive “Open House” Works</h2>

      <div className='flex flex-col items-center justify-center mb-8'>
        <div className='w-11/12 flex items-start md:items-center mt-8 mx-auto'>
          <NumberOne className="mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Call us two weeks prior to when you would like to hold your Open House.</p>
        </div>

        <div className='w-11/12 flex items-start md:items-center mt-8 mx-auto'>
          <NumberTwo className="mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Give us the city and state, day, time frame and location you would like to see candidates.</p>
        </div>

        <div className='w-11/12 flex items-start md:items-center mt-8 mx-auto'>
          <NumberThree className="mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Fill out our Job Order Form with the profile of your candidate, how many openings you have, company comp. plan, benefits and selling points for our marketing campaign. We will also schedule an intake call with our team to go over your open position and details.</p>
        </div>

        <div className='w-11/12 flex items-start md:items-center mt-8 mx-auto'>
          <NumberFour className="mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>HireLive will begin their marketing campaign, promoting your company, open positions, benefits, location, and time of your Open House.</p>
        </div>

        <div className='w-11/12 flex items-start md:items-center mt-8 mx-auto'>
          <NumberFive className="mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>The day before your Open house, after all confirmation calls are completed, you will receive your schedule with candidate's name, contact information and interview time.</p>
        </div>
      </div>

      <div className='flex flex-col items-center justify-center'>
        <Link to="/schedule-open-house">
          <button
            className="bg-site-green-900 hover:bg-site-green-800 text-white uppercase font-display font-bold py-3 px-6 mt-8"
          >
            Schedule Your Open House
          </button>
        </Link>
      </div>
    </div>
  </div>
)

export default OpenHousesHowItWorksSection