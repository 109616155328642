import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const OpenHousesHeroSection = () => (
  <div className="hidden md:grid">
    {/* You can use a GatsbyImage component if the image is dynamic */}
    <StaticImage
      style={{
        gridArea: "1/1",
        // You can set a maximum height for the image, if you wish.
        height: 700,
      }}
      width={1920}
      layout="constrained"
      // This is a presentational image, so the alt should be an empty string
      alt=""
      // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
      src="../../images/backgrounds/open-houses-hiring.jpg"
    />
    <div
      style={{
        // By using the same grid area for both, they are stacked on top of each other
        gridArea: "1/1",
        position: "relative",
        // This centers the other elements inside the hero component
        placeItems: "center",
        display: "grid",
      }}
    >
      {/* Any content here will be centered in the component */}
      <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
        <div className='w-11/12 flex flex-col items-center mx-auto'>
          <h1 className='text-6xl md:text-7xl font-extrabold text-white text-center'>
            <span className='leading-tight'>Stress-Free Recruiting and Interviewing</span>
          </h1>

          <h2 className='text-2xl md:text-3xl font-medium text-white text-center mt-3'>
            Meet Your Qualified Candidates Face to Face with Open Houses
          </h2>

          <Link to="/schedule-open-house">
            <button
              className="bg-site-green-900 hover:bg-site-green-800 text-white uppercase font-display font-bold py-3 px-6 mt-8"
            >
              Schedule Your Open House
            </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default OpenHousesHeroSection