import React from 'react'
import { Link } from "gatsby"
import loadable from '@loadable/component'

const YoutubePlayerLite = loadable(() => import('../common/youtube-player-lite'))

const OpenHousesVideoSection = () => (
  <div className="bg-white w-full border-b">
    <div className="w-11/12 max-w-screen-xl flex flex-col-reverse xl:flex-row items-center justify-center mx-auto my-12">
      <div className='w-full lg:w-1/2 p-4'>
        <h2 className='text-4xl md:text-5xl text-site-indigo-900 font-bold mb-12'>HireLive Open Houses</h2>
        <p className='text-lg text-gray-800'>Companies all across the US who have multiple openings in one location, use HireLive to help them promote their very own Hiring Event to fill any type of position. These events are a great way to see qualified candidates face to face on the day, time and location you prefer.</p>

        <Link to="/schedule-open-house">
          <button
            className="bg-site-green-900 hover:bg-site-green-800 text-white uppercase font-display font-bold py-3 px-6 mt-8"
          >
            Schedule Your Open House
          </button>
        </Link>
      </div>

      <div className='w-full lg:w-1/2 p-4'>
        <YoutubePlayerLite youtubeID="tcbcKNoOozo" title="Stress-Free Recruiting and Interviewing - Hirelive Open Houses" />
      </div>
    </div>
  </div>
)

export default OpenHousesVideoSection