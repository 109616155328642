import React from 'react'
import { Link } from "gatsby"

import CheckIcon from '../../assets/check-icon.svg'

const OpenHousesBenefitsSection = () => (
  <div className="bg-site-indigo-100 w-full py-16 border-b">
    <div className="w-11/12 max-w-3xl mx-auto">
      <h2 className='text-4xl md:text-5xl text-site-indigo-900 text-center font-bold mb-12'>Benefits of HireLive “OPEN HOUSE”</h2>

      <div className='flex flex-col items-center justify-center'>
        <div className='w-11/12 flex items-start mt-8 mx-auto'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>There is an extremely low, flat rate pricing structure. Whether you make 1 hire or multiple hires, there are no additional charges for the service.</p>
        </div>

        <div className='w-11/12 flex items-start mt-8 mx-auto'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Having a respected third-party recruitment firm promoting your company and positions to candidates that may never have heard of your company or never thought about your industry.</p>
        </div>

        <div className='w-11/12 flex items-start mt-8 mx-auto'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Full HireLive marketing power directed at sourcing highly qualified candidates specifically for your company.</p>
        </div>

        <div className='w-11/12 flex items-start mt-8 mx-auto'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Ability to source candidates in any market across the US or help with large ramp ups or seasonal hiring.</p>
        </div>

        <div className='w-11/12 flex items-start mt-8 mx-auto'>
          <CheckIcon className="w-8 mr-4 flex-shrink-0" />
          <p className='text-lg text-gray-800'>Comfort of being able to conduct interviews at your office and also have approved candidates see multiple managers in one day to help expedite the hiring.</p>
        </div>
      </div>

      <div className='flex flex-col items-center justify-center'>
        <Link to="/schedule-open-house">
          <button
            className="bg-site-green-900 hover:bg-site-green-800 text-white uppercase font-display font-bold py-3 px-6 mt-8"
          >
            Schedule Your Open House
          </button>
        </Link>
      </div>
    </div>
  </div>
)

export default OpenHousesBenefitsSection